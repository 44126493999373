var render = function render(){var _vm=this,_c=_vm._self._c;return _c('o-container',{ref:"container"},[_c('v-card',{staticClass:"o-container-content",attrs:{"elevation":"2","shaped":""}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"text-uppercase pl-4 mr-auto"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"d-flex justify-end ml-auto pr-0 pb-2"},[_c('o-button',{staticStyle:{"display":"none"},attrs:{"busy":_vm.busy,"color":"warning","icon":"mdi-trash-can-outline","type":"label-icon","label":"Flush"},on:{"on-click":function($event){return _vm.flush()}}}),_c('o-button',{attrs:{"busy":_vm.busy,"color":"primary","icon":"mdi-sync","type":"label-icon","label":"Refresh"},on:{"on-click":function($event){return _vm.reset()}}})],1),(!_vm.busy)?_c('o-drawer',{attrs:{"auto-close":true,"actions":[ ],"button-icon":"mdi-filter-plus-outline","title":`Data`,"use-button":false,"width":"75vw","visible":_vm.showFilter},on:{"on-close":function($event){_vm.showFilter = false}}},[_c('pre',{staticStyle:{"font-family":"monospace"},domProps:{"innerHTML":_vm._s(_vm.values)}})]):_vm._e()],1),_c('v-divider'),(_vm.busy)?_c('v-skeleton-loader',{attrs:{"loading":_vm.busy,"type":"table","transition":"v-scroll-x-transition"}}):_vm._e(),_c('v-card-text',[_c('o-table',{staticClass:"pa-0 ma-0 pl-10 pr-10",attrs:{"type":"array","data":_vm.keys,"no-count":true,"headers":[
          {
            text: 'Key',
            align: 'start',
            sortable: false,
            value: 'key',
            type: 'text',
          },
          {
            value: 'action',
            type: 'action',
            width: 100,
            actions: [
              {
                key: 'on-item-view',
                label: 'View',
                icon: 'mdi-information-outline',
                color: 'success',
              }, 
              {
                key: 'on-item-delete',
                label: 'delete',
                icon: 'mdi-trash-can-outline',
                color: 'red',
              }, 
            ],
          },
        ]},on:{"on-item-view":_vm.view,"on-item-delete":_vm.remove}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }