<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class="o-container-content">
      <v-card-title class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-trash-can-outline"
            type="label-icon"
            label="Flush"
            style="display:none"
            @on-click="flush()"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />       
        </div>
        
          <o-drawer
            v-if="!busy"
            :auto-close="true"
            :actions="[ ]"
            button-icon="mdi-filter-plus-outline"
            :title="`Data`"
            :use-button="false"
            width="75vw"
            :visible="showFilter" 
            @on-close="showFilter = false"
          >
            <pre v-html="values" style="font-family: monospace;"></pre>  
          </o-drawer>
      </v-card-title>
      <v-divider />

      <v-skeleton-loader
        v-if="busy"
        :loading="busy"
        type="table"
        transition="v-scroll-x-transition"
      />
      <v-card-text>       
        <o-table 
          class="pa-0 ma-0 pl-10 pr-10"
          type="array"
          :data="keys"
          :no-count="true"
          :headers="[
            {
              text: 'Key',
              align: 'start',
              sortable: false,
              value: 'key',
              type: 'text',
            },
            {
              value: 'action',
              type: 'action',
              width: 100,
              actions: [
                {
                  key: 'on-item-view',
                  label: 'View',
                  icon: 'mdi-information-outline',
                  color: 'success',
                }, 
                {
                  key: 'on-item-delete',
                  label: 'delete',
                  icon: 'mdi-trash-can-outline',
                  color: 'red',
                }, 
              ],
            },
          ]"
          @on-item-view="view"
          @on-item-delete="remove"
        />

       
      </v-card-text>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: "RedisChecker", 
  mixins: [RestApi],
  props: {
    dialogName: {
      type: String,
      default: "",
    },
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Redis",
  },
  data() {
    return {
      title: "Redis",
      showFilter: false,
      busy: false,
      dropBusy: false,
      keys: [],
      values: {},
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  mounted() {    
    this.reset();
  },
  methods: {
    reset() {
      this.busy = true;
      this.showFilter = false;
      setTimeout(async () => {
        await this.getData(`/rest/redis`, null)
        .then((response) => {
          if (response.data != {}) {
            this.keys = response.data.map((d) => {
              return { key: d}
            }) 
          } else {
            this.$refs.container.snackBar(
              `No record found.mdi-text-box-search-outline`,
              `red`,
              2000
            );
          } 
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
        });
      }, 200);
    }, 
    async view(item) {
      await this.getData(`/rest/redis/${item.key}`, null)
        .then((response) => {
          if (response && response.data && response.data.value) {          
            try {
              this.values = JSON.parse(response.data.value)
            } catch (ex) {
              this.values = response.data.value
            }
            this.showFilter = true
          } else {
            this.values = {};
            this.$refs.container.snackBar(
              `No record found.mdi-text-box-search-outline`,
              `red`,
              2000
            );
          } 
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
        });
    },
    async remove(item) {
      await this.deleteData(`/rest/redis/${item.key}`, null)
        .then((response) => {
          if (response) {
              this.reset()
          } else {
            this.$refs.container.snackBar(
              `No record found.mdi-text-box-search-outline`,
              `red`,
              2000
            );
          } 
        
        })
        .catch((err) => {
          this.busy = false;
        });
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .f1 {
  top: 0px !important;
}
::v-deep .f2 {
  top: 28px !important;
}
</style>
