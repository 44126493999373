<template>
  <RedisChecker dialog-name="Redis Checker" />
</template>
<script>
import RedisChecker from '@/views/app/checker/Redis'

export default {
  name: 'RedisWrapper',
  components: {
    RedisChecker
  }
}
</script>
